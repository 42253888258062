import React from 'react';
import { withStyles, CircularProgress } from '@material-ui/core';

import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import { Switch, Route, Redirect } from 'react-router-dom';
import authStyle from '../../assets/jss/material-dashboard-react/layouts/authStyle';

const Login = React.lazy(() => import('../../views/Login'));

export default withStyles(authStyle)(({ classes, match, ...rest }) => {
	return (
		<GridContainer classnames={classes.wrapper}>
			<GridItem sm={3} md={3} />
			<GridItem xs={12} sm={6} md={6}>
				<React.Suspense fallback={<CircularProgress />}>
					<Switch>
						<Route
							path={match.path}
							exact
							component={() => <Redirect to={`/${match.path}/login`} />}
						/>
						<Route
							path={`${match.path}/login`}
							component={props => <Login {...props} />}
						/>
					</Switch>
				</React.Suspense>
			</GridItem>
			<GridItem sm={3} md={3} />
		</GridContainer>
	);
});
