import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import EN from './locales/en/translation.json';
import HE from './locales/he/translation.json';
// the translations
// (tip move them in a JSON file and import them)
const resources = {
	en: {
		translation: EN
	},
	he: {
		translation: HE
	}
};

i18n.use(initReactI18next).init({
	resources,
	lng: 'he',
	debug: false,
	fallbackLng: 'he',

	keySeparator: false, // we do not use keys in form messages.welcome

	interpolation: {
		escapeValue: false // react already safes from xss
	}
});

export default i18n;
