/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Sidebar from '../../components/Sidebar/Sidebar';
import dashboardRoutes from '../../routes/dashboard';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import dashboardStyle from '../../assets/jss/material-dashboard-react/layouts/dashboardStyle';
import firebase from 'firebase/app';
import 'firebase/auth';
import image from '../../assets/img/sidebar-2.jpg';
import logo from '../../assets/img/logo.png';

import AuthRequired from '../../providers/AuthRequired';
import { Loading } from '../../components';
const ProjectEdit = React.lazy(() => import('../../views/Projects/Edit')); //eslint-disable-line
const PlaybackEdit = React.lazy(() => import('../../views/Playbacks/Edit'));

const switchRoutes = (
	<React.Suspense
		fallback={
			<div
				style={{
					width: '100%',
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}>
				<Loading />
			</div>
		}>
		<Switch>
			{dashboardRoutes.map((prop, key) => {
				if (prop.redirect)
					return <Redirect from={prop.path} to={prop.to} key={key} exact />;

				return (
					<Route
						path={prop.path}
						component={props => <prop.component {...props} />}
						key={key}
					/>
				);
			})}
		</Switch>
	</React.Suspense>
);

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mobileOpen: false
		};
		this.resizeFunction = this.resizeFunction.bind(this);
		this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
	}
	handleDrawerToggle() {
		this.setState({ mobileOpen: !this.state.mobileOpen });
	}
	getRoute() {
		return this.props.location.pathname !== '/maps';
	}
	resizeFunction() {
		if (window.innerWidth >= 960) {
			this.setState({ mobileOpen: false });
		}
	}
	componentDidMount() {
		window.addEventListener('resize', this.resizeFunction);
	}

	mainPanel = React.createRef();

	componentDidUpdate(e) {
		if (e.history.location.pathname !== e.location.pathname) {
			this.mainPanel.current.scrollTop = 0;
			if (this.state.mobileOpen) {
				this.setState({ mobileOpen: false });
			}
		}
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.resizeFunction);
	}
	render() {
		const { classes, ...rest } = this.props;
		return (
			<AuthRequired>
				<div className={classes.wrapper}>
					<BreadcrumbsItem to="/">SmartClip</BreadcrumbsItem>
					<Sidebar
						routes={dashboardRoutes.filter(item => !item.hidden)}
						//logoText={'Smart-Clip'}
						logo={logo}
						image={image}
						handleDrawerToggle={this.handleDrawerToggle}
						open={this.state.mobileOpen}
						color="blue"
						{...rest}
					/>
					<div className={classes.mainPanel} ref={this.mainPanel}>
						<Header
							routes={dashboardRoutes}
							handleDrawerToggle={this.handleDrawerToggle}
							{...rest}
						/>
						{/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
						{this.getRoute() ? (
							<div className={classes.content}>
								<div className={classes.container}>{switchRoutes}</div>
							</div>
						) : (
							<div className={classes.map}>{switchRoutes}</div>
						)}
						{/*this.getRoute() ? <Footer /> : null*/}
					</div>
				</div>
			</AuthRequired>
		);
	}
}

App.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(App);
