import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

/* fetch('/__/firebase/init.json').then(response => {
	firebase.initializeApp(response.json());
}); */

if (!firebase.apps.length) {
  const config = {
    apiKey: "AIzaSyBtnKEFmkQ6GrHH0IMFg_yvRlqOD6fSCa0",
    authDomain: "smartclipid.firebaseapp.com",
    databaseURL: "https://smartclipid.firebaseio.com",
    projectId: "smartclipid",
    storageBucket: "smartclipid.appspot.com",
    messagingSenderId: "1084086527529",
  };
  firebase.initializeApp(config);

  if (process.env.NODE_ENV === "production") {
    firebase.firestore().enablePersistence();
  }
}
