import './config/firebase';
import './assets/css/material-dashboard-react.css';
import './providers/LocaleProvider';
import 'firebase/firestore';

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import Button from './components/CustomButtons/Button';
import React from 'react';
import { ThroughProvider } from 'react-through';
import { Typography } from '@material-ui/core';
import firebase from 'firebase/app';
import { getRole } from './core/Utils/roleExtractor';
import indexRoutes from './routes/index';
import moment from 'moment';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const App = () => {
	return (
		<ThroughProvider>
			<Router>
				<>
					<Switch>
						{indexRoutes.map((prop, key) => {
							return (
								<Route path={prop.path} component={prop.component} key={key} />
							);
						})}
					</Switch>
					<Route component={NotificationsHandler} />
				</>
			</Router>
		</ThroughProvider>
	);
};
export default App;

const NotificationsHandler = props => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { t } = useTranslation();

	React.useEffect(() => {
		firebase
			.firestore()
			.collection('projects')
			.where('status', '==', 'ASSIGNMENT')
			.where('assignmentDeadline', '<=', moment().unix())
			.get()
			.then(snap => {
				snap.docs.forEach(doc => {
					const client = getRole(doc.data().roles, 'admin');
					if (!client.phoneNumber) {
						return;
					}

					if (
						props.location.pathname !== '/projects' &&
						props.location.pathname !== '/'
					) {
						return;
					}

					const phone = client.phoneNumber;
					enqueueSnackbar(
						t(
							'{{client}} did not finish assignments in time. deadline was {{deadline}}. phone number {{phoneNumber}}',
							{
								client: client.displayName,
								deadline: moment
									.unix(doc.data().assignmentDeadline)
									.format('DD/MM/YYYY'),
								phoneNumber: parsePhoneNumberFromString(
									phone,
									'IL'
								).formatNational()
							}
						),
						{
							persist: true,
							variant: 'error',
							preventDuplicate: true,
							action: function dismiss(key) {
								return (
									<React.Fragment>
										<Button onClick={() => closeSnackbar(key)}>
											<Typography>{t('GOT IT')}</Typography>
										</Button>

										<Button
											onClick={() => {
												props.history.push(`/projects/${doc.id}`);
												closeSnackbar(key);
											}}
										>
											<Typography>{t('Go to project')}</Typography>
										</Button>
									</React.Fragment>
								);
							}
						}
					);
				});

				if (
					snap.docs.length > 1 &&
					props.location.pathname === '/projects' &&
					props.location.pathname !== '/'
				) {
					enqueueSnackbar('', {
						persist: true,
						variant: 'error',
						preventDuplicate: true,
						action: function dismiss() {
							return (
								<React.Fragment>
									<Button onClick={() => closeSnackbar()}>
										<Typography>{t('Close all')}</Typography>
									</Button>
								</React.Fragment>
							);
						}
					});
				}
			});
		return closeSnackbar;
	}, []);
	return <div />;
};
