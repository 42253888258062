export function getRole(roles, role) {
	try {
		const [uid, info] = Object.entries(roles).find(
			item => item[1].role === role
		);
		return { uid, ...info };
	} catch (e) {
		//console.log(e);
		return {};
	}
}

export function convertUserToRole(user, role) {
	return {
		[user.uid]: {
			displayName: user.displayName,
			role,
			phoneNumber: user.phoneNumber
		}
	};
}

export function replaceRole(roles = {}, roleToReplace, user) {
	const rolesMap = Object.entries(roles)
		.filter(([uid, { role }]) => role !== roleToReplace)
		.reduce((acc, cur) => {
			return { ...acc, [cur[0]]: cur[1] };
		}, {});
	return {
		...rolesMap,
		[user.uid]: {
			displayName: user.displayName,
			phoneNumber: user.phoneNumber,
			role: roleToReplace
		}
	};
}
