import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { withTranslation } from 'react-i18next';
import Hidden from '@material-ui/core/Hidden';
// @material-ui/icons
import Menu from '@material-ui/icons/Menu';
// core components
//import HeaderLinks from './HeaderLinks';
import headerStyle from '../../assets/jss/material-dashboard-react/components/headerStyle';
import { Typography } from '@material-ui/core';
import { Breadcrumbs } from 'react-breadcrumbs-dynamic';

const CrumbText = props => (
	<Typography variant="body2" style={{ display: 'inline' }} {...props} />
);

function Header({ ...props }) {
	const { classes, color } = props;
	const appBarClasses = classNames({
		[' ' + classes[color]]: color
	});
	/* function makeBrand() {
		var name;

		props.routes.map((prop, key) => {
			if (prop.path === props.location.pathname) {
				name = prop.navbarName;
			}
			return null;
		});
		return t(name) || '';
	}
 */
	return (
		<AppBar className={classes.appBar + appBarClasses}>
			<Toolbar className={classes.container}>
				<div className={classes.flex}>
					{/* Here we create navbar brand, based on route name */}
					<Breadcrumbs
						container={p => (
							<div {...p} style={{ direction: 'rtl', textAlign: 'right' }} />
						)}
						separator={<CrumbText>{' / '}</CrumbText>}
						item={({ to, ...rest }) => (
							<NavLink to={to}>
								<CrumbText {...rest} />
							</NavLink>
						)}
					/>
				</div>
				<Hidden smDown implementation="css">
					{/* 	<HeaderLinks /> */}
				</Hidden>
				<Hidden mdUp implementation="css">
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={props.handleDrawerToggle}>
						<Menu />
					</IconButton>
				</Hidden>
			</Toolbar>
		</AppBar>
	);
}

Header.propTypes = {
	classes: PropTypes.object.isRequired,
	color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger'])
};

export default withTranslation()(withStyles(headerStyle)(Header));
