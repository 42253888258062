import React from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { Redirect } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

class AuthRequired extends React.Component {
    state = {
        authInitiated: false,
        isAuthenticated: false
    };
    componentDidMount() {
        this.unsubscribe = firebase.auth().onAuthStateChanged(user => {
            if (user) {
                firebase
                    .firestore()
                    .collection("users")
                    .doc(user.uid)
                    .get()
                    .then(doc => {
                        const securiryGroup = doc.data().securityGroup;
                        if (
                            securiryGroup !== "ADMIN" &&
                            securiryGroup !== "EDITOR"
                        ) {
                            this.setState(() => ({
                                authInitiated: true,
                                isAuthenticated: false
                            }));
                            firebase.auth().signOut();
                        } else {
                            this.setState(() => ({
                                authInitiated: true,
                                isAuthenticated: !!user
                            }));
                        }
                    });
            } else {
                this.setState(() => ({
                    authInitiated: true,
                    isAuthenticated: false
                }));
            }
        });
    }

    componentWillUnmount() {
        if (this.unsubscribe) this.unsubscribe();
    }
    render() {
        if (!this.state.authInitiated)
            return (
                <div
                    style={{
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <CircularProgress />
                </div>
            );
        if (this.state.authInitiated && !this.state.isAuthenticated)
            return <Redirect to="/auth/login" />;
        return this.props.children;
    }
}

export default AuthRequired;
