import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import settings from '../package.json';
import { SnackbarProvider } from 'notistack';
import StylesProvider from './providers/StylesProvider';

if (process.env.NODE_ENV === 'development') {
	console.log('smartclip v', settings.version);
}
ReactDOM.render(
	<StylesProvider>
		<SnackbarProvider>
			<App />
		</SnackbarProvider>
	</StylesProvider>,
	document.getElementById('root')
);
