import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import React from 'react';
import { create } from 'jss';
import rtl from 'jss-rtl';

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

// Custom Material-UI class name generator.

const theme = createMuiTheme({
	typography: {
		useNextVariants: true
	},

	direction: 'rtl', //can be set to rtl. also dynamically
	palette: {
		background: {
			default: '#e4e5e6'
		},
		primary: { main: '#f77868', contrastText: '#ffffff' },
		secondary: { main: '#fcec64' },
		trinary: { main: '#a0a09f' }
	}
});

const styledProvider = props => (
	<div dir="rtl">
		<CssBaseline />
		<StylesProvider jss={jss}>
			<MuiThemeProvider theme={theme}>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					{props.children}
				</MuiPickersUtilsProvider>
			</MuiThemeProvider>
		</StylesProvider>
	</div>
);

export default styledProvider;
