import { lazy } from "react";
// @material-ui/icons
import {
  //Dashboard,
  SupervisorAccount,
  Folder,
  //	MusicNote,
  AppsRounded,
  Settings,
  Build,
  ///	CameraRoll
} from "@material-ui/icons";

/* const UserProfile = lazy(() =>
	import('../views/UserProfile/UserProfile')
); */

const dashboardRoutes = [
  /* 	{
		path: '/dashboard',
		sidebarName: 'Dashboard',
		navbarName: 'Dashboard',
		icon: Dashboard,
		component: lazy(() => import('../views/Dashboard/Dashboard'))
	}, */
  {
    path: "/projects",
    sidebarName: "Projects",
    navbarName: "Projects",
    icon: Folder,
    component: lazy(() => import("../views/Projects")),
  },
  /* 	{
		path: '/sketches',
		sidebarName: 'Sketches',
		navbarName: 'Sketches',
		icon: CameraRoll,
		component: lazy(() => import('../views/Sketches'))
	},
	{
		path: '/playbacks',
		sidebarName: 'Playbacks',
		navbarName: 'Playbacks',
		icon: MusicNote,
		component: lazy(() => import('../views/Playbacks'))
	}, */
  {
    path: "/users",
    sidebarName: "Users",
    navbarName: "Users",
    icon: SupervisorAccount,
    component: lazy(() => import("../views/Users")),
  },
  {
    path: "/general",
    sidebarName: "General",
    navbarName: "General",
    icon: AppsRounded,
    component: lazy(() => import("../views/General")),
  },
  {
    path: "/tools",
    sidebarName: "Tools",
    navbarName: "Tools",
    icon: Build,
    component: lazy(() => import("../views/Tools")),
  },
  {
    path: "/settings",
    sidebarName: "Settings",
    navbarName: "Settings",
    icon: Settings,
    component: lazy(() => import("../views/Settings")),
  },

  { redirect: true, path: "/", to: "/projects", navbarName: "Redirect" },
];

export default dashboardRoutes;
