import { CircularProgress } from '@material-ui/core';
import React from 'react';

const styles = {
	width: '100%',
	height: '100%',
	minHeight: 300,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center'
};
const Loading = () => (
	<div style={styles}>
		<CircularProgress />
	</div>
);

export default Loading;
